<template>
	<div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 rounded-md">
		<div>
			<div class="sm:hidden">
				<select
					v-model="myQuery"
					aria-label="Selected tab"
					class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
					@change="selected"
				>
					<option selected value="account">All Accounts</option>
					<option value="account.create">Create Account</option>
					<option value="account.add">Existing Account</option>
				</select>
			</div>
			<div class="hidden sm:block">
				<div class="border-b border-gray-200">
					<nav class="-mb-px flex">
						<router-link exact :to="{ name: 'account' }" class="tab" active-class="activeTab">
							All Accounts
						</router-link>
						<router-link :to="{ name: 'account.create' }" class="tab" active-class="activeTab">
							Create Account
						</router-link>
						<router-link :to="{ name: 'account.add' }" class="tab" active-class="activeTab">
							Existing Account
						</router-link>
					</nav>
				</div>
			</div>
		</div>
		<div>
			<router-view />
		</div>
	</div>
</template>

<script>
export default {
	name: 'AccountHome',
	data() {
		return {
			myQuery: 'account'
		}
	},
	metaInfo() {
		return {
			title: 'Accounts'
		}
	},
	methods: {
		selected() {
			this.$router.push({
				name: this.myQuery
			})
		}
	}
}
</script>
